import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import StarIcon from '@mui/icons-material/Star';
import InlineRating from './InlineRating';
import Rating from '@mui/material/Rating';
import { Helmet } from 'react-helmet-async';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.vintagestores.org';

const WebsiteDetailPage = () => {
    const { websiteId } = useParams();
    const [website, setWebsite] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [imageError, setImageError] = useState(false);
    const [isRatingOpen, setIsRatingOpen] = useState(false);

    const fetchWebsite = async () => {
        try {
            const response = await fetch(`${API_URL}/api/websites?id=${websiteId}`);
            if (!response.ok) {
                throw new Error('Website not found');
            }
            const data = await response.json();
            
            const websiteData = Array.isArray(data) 
                ? data.find(site => site.id.toString() === websiteId.toString())
                : data;
            
            if (!websiteData) {
                throw new Error('Website not found');
            }
            
            websiteData.stars = websiteData.stars ? parseFloat(websiteData.stars) : 0;
            setWebsite(websiteData);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWebsite();
    }, [websiteId]);

    useEffect(() => {
        if (website && website.screenshot_url) {
            const paths = [
                `${API_URL}/screenshots/website-${website.id}.jpg`,
                `${API_URL}/public/screenshots/website-${website.id}.jpg`,
                `/screenshots/website-${website.id}.jpg`,
                `/public/screenshots/website-${website.id}.jpg`
            ];
            
            const img = new Image();
            let currentPathIndex = 0;

            img.onload = () => {
                setImagePath(paths[currentPathIndex]);
                setImageError(false);
            };

            img.onerror = () => {
                currentPathIndex++;
                if (currentPathIndex < paths.length) {
                    img.src = paths[currentPathIndex];
                } else {
                    setImageError(true);
                }
            };

            img.src = paths[0];
        }
    }, [website, API_URL]);

    useEffect(() => {
        // Load Instagram embed script
        const script = document.createElement('script');
        script.src = '//www.instagram.com/embed.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Clean up script when component unmounts
            const existingScript = document.querySelector('script[src="//www.instagram.com/embed.js"]');
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
        };
    }, []);

    const handleRatingSubmit = async (ratings) => {
        try {
            const response = await fetch(`${API_URL}/api/ratings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    website_id: websiteId,
                    ...ratings
                }),
            });
            
            if (!response.ok) throw new Error('Failed to submit rating');
            
            await fetchWebsite();
            setIsRatingOpen(false);
        } catch (error) {
            console.error('Error submitting rating:', error);
        }
    };

    if (loading) {
        return (
            <Container sx={{ marginTop: 4 }}>
                <Typography variant="h4" gutterBottom>Loading...</Typography>
            </Container>
        );
    }

    if (error || !website) {
        return (
            <Container sx={{ marginTop: 4 }}>
                <Typography variant="h4" gutterBottom>Website not found</Typography>
            </Container>
        );
    }
    
    const parseMarkdownToBold = (text) => {
        let cleanText = text
            .replace(/\s+([,.:])/g, '$1')
            .replace(/•\s*/g, '• ')
            .trim();

        const parts = cleanText.split(/(\*\*.*?\*\*)/);
        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return (
                    <Typography
                        key={index}
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {part.slice(2, -2).trim()}
                    </Typography>
                );
            }
            return part;
        });
    };
    
    return (
        <Container maxWidth="lg" sx={{ marginTop: 4, marginBottom: 8, width: '90%' }}>
            {website && (
                <Helmet>
                    <title>{`${website.name} - Vintage Stores Directory`}</title>
                    <meta 
                        name="description" 
                        content={website.short_description || website.long_description?.split('\n')[0] || `Discover ${website.name} - A vintage store in ${website.location}`} 
                    />
                    <meta 
                        name="keywords" 
                        content={`vintage store, ${website.categories?.join(', ')}, ${website.location}, ${website.name}`} 
                    />
                    <meta property="og:title" content={`${website.name} - Vintage Store`} />
                    <meta 
                        property="og:description" 
                        content={website.short_description || website.long_description?.split('\n')[0]} 
                    />
                    {imagePath && <meta property="og:image" content={imagePath} />}
                    <meta property="og:type" content="website" />
                </Helmet>
            )}

            {/* Breadcrumbs */}
            <Box sx={{ marginBottom: 2 }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Home
                    </Link>
                    <Typography color="text.primary">{website.name}</Typography>
                </Breadcrumbs>
            </Box>

            {/* Header Section */}
            <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', md: 'row' },
                gap: 4,
                marginBottom: 4 
            }}>
                {/* Left side of header */}
                <Box sx={{ flex: 2 }}>
                    {/* Title */}
                    <Typography
                        variant="h3" 
                        
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: 2 }}
                    >
                        {website.name}
                    </Typography>

                    {/* Location - Visible on mobile */}
                    <Box sx={{ 
                        display: { xs: 'flex', md: 'none' },
                        alignItems: 'center', 
                        gap: 0.5,
                        marginBottom: 1.5
                    }}>
                        <LocationOnIcon color="action" />
                        <Typography variant="body2" color="text.secondary">
                            {website.location}
                        </Typography>
                    </Box>

                    {/* Container for desktop layout */}
                    <Box sx={{ 
                        display: { xs: 'none', md: 'flex' },
                        alignItems: 'center',
                        gap: 2,
                        marginBottom: 2
                    }}>
                        
                        {/* Location - Desktop */}
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 0.5,
                            minWidth: 'fit-content'
                        }}>
                            <LocationOnIcon color="action" />
                            <Typography variant="body2" color="text.secondary">
                                {website.location}
                            </Typography>
                        </Box>

                        {/* Divider */}
                        <Typography variant="body2" color="text.secondary">•</Typography>

                        {/* Categories - Desktop */}
                        <Box sx={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: 1,
                            flex: 1
                        }}>
                            {website?.categories?.map((category, index) => (
                                <Chip
                                    key={index}
                                    label={category}
                                    variant="outlined"
                                    size="small"
                                    sx={{ 
                                        borderRadius: '16px',
                                        '&:hover': { backgroundColor: 'action.hover' }
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    {/* Scrollable Categories - Mobile */}
                    <Box sx={{ 
                        display: { xs: 'block', md: 'none' },
                        marginBottom: 1.5,
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }}>
                        <Box sx={{ 
                            display: 'flex',
                            gap: 1,
                            pb: 0.5,
                            width: 'fit-content',
                        }}>
                            {website?.categories?.map((category, index) => (
                                <Chip
                                    key={index}
                                    label={category}
                                    variant="outlined"
                                    size="small"
                                    sx={{ 
                                        borderRadius: '16px',
                                        flexShrink: 0,
                                        '&:hover': { backgroundColor: 'action.hover' }
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>

                {/* Right side CTA card */}
                <Box sx={{ 
                    flex: 1,
                    minWidth: { xs: '100%', md: '300px' },
                    alignSelf: 'flex-start'
                }}>
                    {/* Action Buttons */}
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        {/* Website Button - Changes state based on website URL existence */}
                        <Button 
                            variant="contained" 
                            color="primary" 
                            fullWidth
                            href={website.website || undefined}
                            target="_blank" 
                            rel="noopener noreferrer"
                            disabled={!website.website}
                            endIcon={website.website ? <OpenInNewIcon /> : null}
                            sx={{ 
                                height: '48px', 
                                fontSize: '1.1rem', 
                                fontWeight: 'bold',
                                backgroundColor: !website.website ? 'action.disabledBackground' : undefined,
                                color: !website.website ? 'text.disabled' : undefined,
                                '&.Mui-disabled': {
                                    color: 'text.disabled',
                                    backgroundColor: 'action.disabledBackground',
                                }
                            }}
                        >
                            {website.website ? 'Visit Website' : 'No Website'}
                        </Button>

                        {/* Instagram Icon Button - Only show if Instagram URL exists */}
                        {website.instagram_url && (
                            <Button
                                variant="outlined"
                                color="primary"
                                href={website.instagram_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ 
                                    minWidth: '48px',
                                    width: '48px',
                                    height: '48px',
                                    padding: 0
                                }}
                            >
                                <InstagramIcon />
                            </Button>
                        )}
                    </Box>

                    {/* Rating Section */}
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2,
                        mt: 2
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Rating 
                                value={Number(website.stars) || 0}
                                precision={0.1}
                                readOnly
                                sx={{
                                    color: 'primary.main',
                                    '& .MuiRating-iconFilled': {
                                        color: 'primary.main',
                                    },
                                    '& .MuiRating-iconEmpty': {
                                        color: 'text.secondary',
                                    }
                                }}
                            />
                            <Typography 
                                variant="h6" 
                                color="text.secondary"
                                sx={{ 
                                    ml: 1, 
                                    mt: 0.5,
                                    fontSize: '1rem'
                                }}
                            >
                                {website.stars ? Number(website.stars).toFixed(1) : "No ratings"}
                            </Typography>
                        </Box>
                        <Typography
                            component="span"
                            sx={{
                                color: 'primary.main',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline'
                                },
                                fontSize: '1rem'
                            }}
                            onClick={() => setIsRatingOpen(!isRatingOpen)}
                        >
                            Rate store
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Inline Rating Section */}
            <InlineRating 
                onSubmit={handleRatingSubmit}
                isOpen={isRatingOpen}
                onToggle={() => setIsRatingOpen(!isRatingOpen)}
            />

            {/* Hero Image - Only show if screenshot_url exists */}
            {website && website.screenshot_url && !imageError && (
                <Paper 
                    elevation={3} 
                    sx={{ 
                        marginBottom: 4, 
                        overflow: 'hidden',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider'
                    }}
                >
                    <Box
                        component="img"
                        src={imagePath || `${API_URL}/screenshots/website-${website.id}.jpg`}
                        alt={`${website.name} screenshot`}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '600px',
                            objectFit: 'cover',
                            bgcolor: 'grey.100',
                        }}
                        onError={(e) => {
                            console.error('Image failed to load:', e.target.src);
                            console.error('Current image path:', imagePath);
                            setImageError(true);
                        }}
                    />
                </Paper>
            )}

            {/* About Section */}
            <Box sx={{ maxWidth: '100%', paddingBottom: '2rem' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium' }}>
                    About
                </Typography>
                {website.long_description &&
                    website.long_description.split('\n').map((paragraph, index) => (
                        <Box key={index} sx={{ marginBottom: '1.5rem' }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'text.secondary',
                                    lineHeight: 1.8,
                                    whiteSpace: 'pre-line',
                                }}
                            >
                                {parseMarkdownToBold(paragraph)}
                            </Typography>
                        </Box>
                    ))}
            </Box>
        </Container>
    );
};

export default WebsiteDetailPage;
