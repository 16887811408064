// HomePage.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import CardList from '../components/CardList';
import { Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import SearchBar from '../components/SearchBar';
import CategoryFilter from '../components/CategoryFilter';
import LocationFilter from '../components/LocationFilter';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.vintagestores.org';

// console.log('API_URL:', process.env.REACT_APP_API_URL);
// console.log('NODE_ENV:', process.env.NODE_ENV);

const allLocations = [
    'Nashville, TN',
    'Los Angeles, CA',
    'New York, NY',
    'Austin, TX',
    'Portland, OR',
    'Chicago, IL',
    'Seattle, WA',
    'San Francisco, CA',
    'Denver, CO',
    'Atlanta, GA'
];

const allCategories = [
    'Vintage',
    'Antiques',
    'Mid-Century Modern',
    'Furniture',
    'Home Decor',
    'Art',
    'Clothing',
    'Jewelry',
    'Records',
    'Books'
];

const fetchWithRedirect = async (url, options = {}) => {
    const defaultOptions = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        mode: 'cors'
    };
    
    const response = await fetch(url, { ...defaultOptions, ...options });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
};

const HomePage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [websites, setWebsites] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState(searchParams.get('search') || '');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [locations, setLocations] = useState([]);
    const [categories, setCategories] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const pageRef = useRef(1);
    const isLoadingRef = useRef(false);

    const observerRef = useRef();
    const loadingRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleSearch = (value) => {
        setSearchQuery(value);
        setSearchParams(value ? { search: value } : {});
    };

    const fetchWebsites = useCallback(async () => {
        if (isLoadingRef.current) {
            return;
        }
        isLoadingRef.current = true;
        setIsLoading(true);
        setError(null);
        
        try {
            const limit = 50;
            const offset = (pageRef.current - 1) * limit;
            
            let url = `${API_URL}/api/websites?limit=${limit}&offset=${offset}`;
            
            if (selectedLocation && selectedLocation !== 'All Locations') {
                url += `&location=${encodeURIComponent(selectedLocation)}`;
            }
            
            if (selectedCategories.length > 0) {
                url += `&categories=${encodeURIComponent(selectedCategories.join(','))}`;
            }
            
            if (searchQuery) {
                url += `&search=${encodeURIComponent(searchQuery)}`;
            }

            const response = await fetchWithRedirect(url);
            const data = await response.json();
            
            if (!Array.isArray(data)) {
                throw new Error('Invalid response format');
            }

            if (pageRef.current === 1) {
                setWebsites(data);
            } else {
                setWebsites(prev => [...prev, ...data]);
            }
            
            setHasMore(data.length === limit);
            if (data.length === limit) {
                pageRef.current += 1;
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError(`Failed to load: ${error.message}`);
            setWebsites([]);
            setHasMore(false);
        } finally {
            isLoadingRef.current = false;
            setIsLoading(false);
        }
    }, [selectedLocation, selectedCategories, searchQuery]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !isLoadingRef.current) {
                    fetchWebsites();
                }
            },
            { threshold: 0.1 }
        );

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => observer.disconnect();
    }, [hasMore, fetchWebsites]);

    useEffect(() => {
        pageRef.current = 1;
        fetchWebsites();
    }, [selectedLocation, selectedCategories, searchQuery]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const [locationsRes, categoriesRes] = await Promise.all([
                    fetchWithRedirect(`${API_URL}/api/locations`),
                    fetchWithRedirect(`${API_URL}/api/categories`)
                ]);
                
                const locationsData = await locationsRes.json();
                const categoriesData = await categoriesRes.json();
                
                setLocations(locationsData || []);
                setCategories(categoriesData || []);
            } catch (error) {
                console.error('Error fetching filters:', error);
                setLocations(allLocations);
                setCategories(allCategories);
            }
        };

        fetchFilters();
    }, [API_URL]);

    return (
        <Container 
            sx={{ 
                marginTop: isMobile ? 3 : 8,
                maxWidth: '1200px !important',
                padding: isMobile ? '16px' : '24px',
                width: '100%',
            }}
        >
            <Typography 
                variant="h3" 
                align="center" 
                sx={{ 
                    mb: 1
                }}
            >
                Your home for all things vintage.
            </Typography>
            <Typography 
                variant="h6" 
                align="center" 
                sx={{ 
                    mb: isMobile ? 2 : 8,
                    color: 'text.secondary',
                    fontWeight: 'normal'
                }}
            >
                Discover and rate the best vintage and secondhand shops in the country.
            </Typography>

            {/* Search and Location Rows */}
            <Box 
                sx={{ 
                    mb: 2.5,
                    display: 'flex',
                    flexDirection: 'column',  // Changed to column layout
                    gap: 2,
                    maxWidth: '600px',
                    margin: '20px auto',
                    position: 'relative',
                }}
            >
                <SearchBar 
                    value={searchQuery} 
                    onChange={handleSearch} 
                    sx={{ width: '100%' }}
                />
                <LocationFilter
                    locations={locations}
                    selectedLocation={selectedLocation}
                    onLocationChange={setSelectedLocation}
                    sx={{ width: '100%' }}  // Changed from flex to width
                />
            </Box>

            {/* Category Chips Row - Centered */}
            <Box 
                sx={{ 
                    mb: 0.25,
                    display: 'flex',
                    justifyContent: 'center'  // Centers the CategoryFilter
                }}
            >
                <Box sx={{ maxWidth: '800px', width: '100%' }}>
                    <CategoryFilter
                        selectedCategories={selectedCategories}
                        onCategorySelect={setSelectedCategories}
                    />
                </Box>
            </Box>

            {error && (
                <Typography 
                    color="error" 
                    align="center" 
                    sx={{ 
                        my: 2,
                        px: 2,
                        wordBreak: 'break-word'
                    }}
                >
                    {error}
                </Typography>
            )}

            {isLoading && websites.length === 0 ? (
                <Typography align="center" sx={{ my: 2 }}>
                    Loading stores...
                </Typography>
            ) : websites.length > 0 ? (
                <CardList websites={websites} />
            ) : (
                <Typography align="center" sx={{ my: 2 }}>
                    No stores found. Please try adjusting your filters.
                </Typography>
            )}
            
            <div 
                ref={loadingRef} 
                style={{ 
                    height: '50px', 
                    margin: '20px 0',
                    textAlign: 'center'
                }}
            >
                {hasMore && !error && websites.length > 0 && (
                    <Typography variant="body2">
                        Loading more...
                    </Typography>
                )}
            </div>
        </Container>
    );
}

export default HomePage;
