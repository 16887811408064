// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomePage from './pages/HomePage';
import WebsiteDetailPage from './components/WebsiteDetailPage';
import Header from './components/Header';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';
import { HelmetProvider } from 'react-helmet-async';

// Create a component to use the hook since hooks must be used inside components
function AppContent() {
  useGoogleAnalytics();
  
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/website/:websiteId" element={<WebsiteDetailPage />} />
        <Route path="/discover" element={<div>Discover</div>} />
        <Route path="/submit" element={<div>Submit</div>} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;
